exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-student-portal-tsx": () => import("./../../../src/pages/student-portal.tsx" /* webpackChunkName: "component---src-pages-student-portal-tsx" */),
  "component---src-pages-studios-tsx": () => import("./../../../src/pages/studios.tsx" /* webpackChunkName: "component---src-pages-studios-tsx" */),
  "component---src-pages-teachers-tsx": () => import("./../../../src/pages/teachers.tsx" /* webpackChunkName: "component---src-pages-teachers-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

