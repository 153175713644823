module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.4_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Six Strings Townsville","short_name":"sixstringstsv","start_url":"/","icon":"src/assets/svg/Logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e25cf7f3d19afbf06b7b8883ca8991ac"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-amplitude-analytics@3.2.0_gatsby@4.25.4/node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"80315700d020f3065a90562956784eac","head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/","/?hjVerifyInstall=1336117","/admin/**"],"eventTypes":{"outboundLinkClick":"OUTBOUND_LINK_CLICK","pageView":"PAGE_VIEW"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.4_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@4.9.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
